// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-competition-tsx": () => import("./../../../src/templates/competition.tsx" /* webpackChunkName: "component---src-templates-competition-tsx" */),
  "component---src-templates-entries-tsx": () => import("./../../../src/templates/entries.tsx" /* webpackChunkName: "component---src-templates-entries-tsx" */),
  "component---src-templates-latest-updates-tsx": () => import("./../../../src/templates/latest-updates.tsx" /* webpackChunkName: "component---src-templates-latest-updates-tsx" */),
  "component---src-templates-list-view-tsx": () => import("./../../../src/templates/list-view.tsx" /* webpackChunkName: "component---src-templates-list-view-tsx" */),
  "component---src-templates-mini-leagues-tsx": () => import("./../../../src/templates/mini-leagues.tsx" /* webpackChunkName: "component---src-templates-mini-leagues-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-rules-tsx": () => import("./../../../src/templates/rules.tsx" /* webpackChunkName: "component---src-templates-rules-tsx" */)
}

